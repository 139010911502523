import { ChatAdapterExtras } from "@nlux/react";
import { chatAskQuestion } from "@services/service-handlers/ChatService";
import { CustomBatchSendType } from "../types";

export const batchSend: CustomBatchSendType = async (message: string, extras: ChatAdapterExtras, metadata: Record<string, any>) => {
  try {
    const response = await chatAskQuestion(message, metadata);
    return response.answer;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in batchSend:', error);
  }
  return '';
};
