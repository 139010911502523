import {ENV_TYPE} from 'src/types/Types';
const env = process.env.REACT_APP_ENV as ENV_TYPE;

const envConfigs: {
  [e in ENV_TYPE]: {
    reqTimeout: number;
    baseUrl: string;
  };
} = {
  local: {
    reqTimeout: 90000,
    baseUrl: 'http://localhost:8001/api',
  },
  development: {
    reqTimeout: 90000,
    baseUrl: 'https://chatbeta.pcarelink.com/api'
  },
  qa: {
    reqTimeout: 90000,
    baseUrl: 'https://chatqa.pcarelink.com/api'
  },
  staging: {
    reqTimeout: 90000,
    baseUrl: 'https://chatstaging.pcarelink.com/api'
  },
  production: {
    reqTimeout: 90000,
    baseUrl: 'https://chat.pcarelink.com/api'
  }
};

const envConfig = envConfigs[env];
export const config = {
  // Check with the lead dev and update the variables based on the requirement
  REQ_TIME_OUT: envConfig.reqTimeout,
  BASE_URL: envConfig.baseUrl,
  NODE_ENV: process.env.NODE_ENV,
  ENCRYPT_URL_KEY: process.env.REACT_APP_ENCRYPT_URL_KEY,
  ENCRYPT_LOCAL_STORAGE_KEY: process.env.REACT_APP_ENCRYPT_LOCAL_STORAGE_KEY
};
