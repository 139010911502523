export const payers = [
  'Aetna',
  'Anthem BCBS',
  'Anthem Blue Cross',
  'AR',
  'AZ Blue',
  'AZ BlueMCR (Evicore)',
  'BCBS of Albama',
  'BCBS of IL',
  'BCBS of Kansas',
  'BCBS of Louisiana',
  'BCBS of Masschusets',
  'BCBS of Michigan',
  'BCBS of Minnesota',
  'BCBS of Mississipi',
  'BCBS of Montana',
  'BCBS of Nebraska',
  'BCBS of New Mexico',
  'BCBS of North Carolina',
  'BCBS of Oklahoma',
  'BCBS of Rhode Island',
  'BCBS of South Carolina',
  // 'BCBS of Tennesse',
  'BCBS of Texas',
  // 'BCBS of Vermont',
  'BCBS of Wyoming',
  'BCBSof North Dakota',
  'Blue Cross of Idaho',
  'CA Anthem BCBS',
  'CA Blue Shield',
  // 'Carefirst BCBS',
  'CGS ',
  'CO Anthem BCBS',
  'CT Anthem BCBS',
  'Empire BCBS',
  'FCSO',
  'Florida Blue',
  // 'Hawaii Medical Service Association',
  'High Mark BCBS Of Delaware',
  'High Mark of Western Pennsylvania',
  'High Mark Blueshield Central PA',
  'Highmark BCBS of West Virginia',
  'Highmark BCBS of WesternNY',
  'Highmark Blus shield of NENY',
  'Horizon BCBS of New Jersey',
  'NGS',
  'Noridian JF',
  'Noridian JE',
  'Novitas',
  'Palmetto',
  'Premera BCBS',
  'Premera Bluecross Blueshield',
  // 'Regence BCBS',
  'UHC (All States)',
  // 'Wellmark BCBS',
  'WPS'
];

export const proceduresData = [
  'Trigger Point Injection',
  'Epidural Injection Procedures',
  'Facet Joint Injection',
  'Radio Frequency Ablation',
  'Sacroiliac Joint Injection'
];

export const modelOptions = ['Without Summary', 'With Summary'];

export const statesData = [
  'All States',
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Islands',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming'
];

export const payersByStateData = [
  {state: 'All States', payers: ['Aetna ', 'UHC (All States)']},
  {state: 'Alabama', payers: ['BCBS of Alabama', 'Palmetto']},
  {
    state: 'Alaska',
    payers: ['BCBS of Alaska', 'Noridian JF', 'Premera Bluecross Blueshield']
  },
  {
    state: 'Arizona',
    payers: ['AZ BlueMCR (Evicore)', 'Noridian JF', 'AZ Blue']
  },
  {state: 'Arkansas', payers: ['Novitas', 'Arkansas BCBS']},
  {
    state: 'California',
    payers: ['CA Anthem BCBS', 'Noridian JE', 'CA Blue Shield']
  },
  {state: 'Colorado', payers: ['CO Anthem BCBS', 'Novitas']},
  {state: 'Connecticut', payers: ['CT Anthem BCBS', 'NGS']},
  {state: 'Delaware', payers: ['High Mark BCBS Of Delaware', 'Novitas']},
  {state: 'Florida', payers: ['FCSO', 'Florida Blue']},
  {state: 'Georgia', payers: ['Anthem Blue Cross', 'Palmetto']},
  {
    state: 'Hawaii',
    payers: ['Noridian JE']
  },
  {state: 'Idaho', payers: ['Noridian JF', 'Blue Cross of Idaho']},
  {state: 'Illinois', payers: ['NGS', 'BCBS of IL']},
  {state: 'Indiana', payers: ['Anthem BCBS']},
  {state: 'Iowa', payers: ['WPS']},
  {state: 'Kansas', payers: ['WPS', 'BCBS of Kansas']},
  {state: 'Kentucky', payers: ['Anthem BCBS', 'CGS ']},
  {state: 'Louisiana', payers: ['Novitas', 'BCBS of Louisiana']},
  {state: 'Maine', payers: ['Anthem BCBS', 'NGS']},
  {state: 'Maryland', payers: ['Novitas']},
  {state: 'Massachusetts', payers: ['NGS', 'BCBS of Masschusets']},
  {state: 'Michigan', payers: ['WPS', 'BCBS of Michigan']},
  {state: 'Minnesota', payers: ['BCBS of Minnesota', 'NGS']},
  {state: 'Mississippi', payers: ['Novitas', 'BCBS of Mississipi']},
  {state: 'Missouri', payers: ['Anthem BCBS', 'WPS']},
  {state: 'Montana', payers: ['Noridian JF', 'BCBS of Montana']},
  {state: 'Nebraska', payers: ['WPS', 'BCBS of Nebraska']},
  {state: 'Nevada', payers: ['Anthem BCBS', 'Noridian JE']},
  {state: 'New Hampshire', payers: ['Anthem BCBS', 'NGS']},
  {state: 'New Jersey', payers: ['Novitas', 'Horizon BCBS of New Jersey']},
  {state: 'New Mexico', payers: ['Novitas', 'BCBS of New Mexico']},
  {
    state: 'New York',
    payers: [
      'Empire BCBS',
      'Highmark BCBS of WesternNY Highmark Blus shield of NENY',
      'NGS'
    ]
  },
  {state: 'North Carolina', payers: ['Palmetto', 'BCBS of North Carolina']},
  {state: 'North Dakota', payers: ['Noridian JF', 'BCBSof North Dakota']},
  {state: 'Ohio', payers: ['Anthem BCBS', 'CGS ']},
  {state: 'Oklahoma', payers: ['Novitas', 'BCBS of Oklahoma']},
  {state: 'Oregon', payers: ['Noridian JF']},
  {
    state: 'Pennsylvania',
    payers: [
      'High Mark of Western Pennsylvania High Mark Blueshield Central PA',
      'Novitas'
    ]
  },
  {state: 'Rhode Island', payers: ['NGS', 'BCBS of Rhode Island']},
  {state: 'South Carolina', payers: ['Palmetto', 'BCBS of South Carolina']},
  {state: 'South Dakota', payers: ['Noridian JF']},
  {state: 'Tennessee', payers: ['Palmetto']},
  {state: 'Texas', payers: ['Novitas', 'BCBS of Texas']},
  {state: 'Utah', payers: ['Noridian JF']},
  {state: 'Vermont', payers: ['NGS']},
  {state: 'Virginia', payers: ['Anthem  BCBS', 'Palmetto']},
  {state: 'Washington', payers: ['Noridian JF', 'Premera BCBS']},
  {
    state: 'West Virginia',
    payers: ['Highmark BCBS of West Virginia', 'Palmetto']
  },
  {state: 'Wisconsin', payers: ['Anthem BCBS', 'NGS']},
  {state: 'Wyoming', payers: ['BCBS of Wyoming', 'Noridian JF', 'Aetna ']},
  {state: 'District of Columbia', payers: ['Novitas']},
  {state: 'American Samoa', payers: ['Noridian JE']},
  {state: 'Guam', payers: ['Noridian JE']},
  {state: 'Northern Mariana Islands', payers: ['Noridian JE']},
  {state: 'Puerto Rico', payers: ['FCSO']},
  {state: 'Virgin Islands', payers: ['FCSO']}
];
