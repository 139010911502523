import {PromptRendererProps} from '@nlux/react';
import './customization.scss';
import {ReactNode, useState} from 'react';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {Tooltip} from '@mui/material';
type CustomPromptRendererProps = PromptRendererProps;
const CustomPromptRenderer = (props: CustomPromptRendererProps): ReactNode => {
  const {prompt} = props;
  const [tooltipText, setTooltipText] = useState('Copy');
  const handleCopy = () => {
    navigator.clipboard.writeText(prompt);
    setTooltipText('Copied!');
    setTimeout(() => {
      setTooltipText('Copy');
    }, 2000);
  };
  return (
    <div className="custom-prompt-renderer">
      <div className="prompt-container">
        {prompt}
        <span>
          <Tooltip title={tooltipText} arrow>
            <ContentCopyOutlinedIcon
              className="clipboard-icon"
              onClick={handleCopy}
            />
          </Tooltip>
        </span>
      </div>
    </div>
  );
};
export default CustomPromptRenderer;