import {combineReducers, configureStore} from '@reduxjs/toolkit';

import authReducer from '../modules/home/state/auth/AuthStateSlice';

const rootReducer = combineReducers({
  auth: authReducer
});

const store = configureStore({
  reducer: rootReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default store;
