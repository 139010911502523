import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { MenuProps } from '@mui/material/Menu';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import './select-dropdown.scss';

interface DropDownProps {
  options: { value: string; label: string }[];
  title: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  onBlur?: () => void;
  name?: string;
}

const SelectDropDown: React.FC<DropDownProps> = ({
  options,
  title,
  value,
  onChange,
  onBlur,
  name
}) => {
  // Custom MenuProps to adjust elevation and other styles
  const menuProps: Partial<MenuProps> = {
    PaperProps: {
      style: {
        maxHeight: 200, // Adjust max height as needed
        width: 250 // Adjust width as needed
      }
    }
  };

  return (
    <div className="select-container">
      <Box sx={{ display: 'flex' }}>
        <FormControl className="select-control">
          <InputLabel
            id="demo-simple-select-label"
            style={{ textAlign: 'center' }}>
            {title}{' '}
          </InputLabel>
          <Select
            label={title}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            inputProps={{ 'aria-label': 'Without label' }}
            MenuProps={menuProps}
            className="select-input"
            name={name}>
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default SelectDropDown;
