import React from 'react';
import {
  AiChat,
  useAsBatchAdapter,
  useAiChatApi,
  ConversationOptions
} from '@nlux/react';
import '@nlux/themes/nova.css';
import BotAvatar from '@assets/images/pcare-bot-logo.png';
import {batchSend} from '../utils/batchSend';
import SelectDropDown from '@components/buttons/drop-down/SelectDropDown';
import img from '@assets/icons/png/pcarelink_logo.png';
import {statesData, payersByStateData} from '@modules/chat/utils/chat-data';
import {useForm, Controller} from 'react-hook-form';
import './chat.scss';
// import CustomResponseRenderer from './customization/CustomResponseRenderer';
import {IChatFormData} from '../types';
import CustomPromptRenderer from './customization/CustomPromptRenderer';

const Chat = (): React.ReactElement => {
  const api = useAiChatApi();
  const {control, getValues, watch} = useForm<IChatFormData>({
    defaultValues: {
      payer: '',
      state: '',
      modelOption: '',
      procedure: ''
    }
  });
  const stateWatch = watch('state');
  // Function to create metadata from form values
  const getMetadata = () => {
    const values = getValues();
    return {
      payer: values.payer,
      state: values.state,
      modelOption: 'With Summary', // values.modelOption,
      procedure: values.procedure
    };
  };
  const myCustomAdapter = useAsBatchAdapter((message, extras) =>
    batchSend(message, extras, getMetadata())
  );

  const conversationOptions: ConversationOptions = {
    conversationStarters: [
      {
        prompt: 'What is considered conservative therapy?'
      },
      {
        prompt: 'List all diagnosis codes covered epidural steroid injections?'
      },
      {
        prompt:
          'Give a Summary of requirements for spinal cord stimulation trial'
      },
      {
        prompt:
          'List the five major provocative maneuvers to establish SI Joint Pain'
      }
    ]
  };
  const getPayerOptions = () => {
    if (!stateWatch) return [];

    const stateData = payersByStateData.find(item => item.state === stateWatch);
    if (stateData) {
      const sortedPayers = [...stateData.payers].sort((a, b) =>
        a.localeCompare(b)
      );
      return sortedPayers.map(payer => ({value: payer, label: payer}));
    }

    return [];
  };

  return (
    <div className="chat-container">
      <div className="chat-main">
        <div className="chat-header-main">
          <div className="logo-header">
            <img src={img} className="pcare-logo" alt="p-care-logo" />
            <div className="title">
              {' '}
              <div>Prior Authorizations</div>{' '}
              <div className="sub-title">AI Chatbot</div>
            </div>
          </div>
          <div className="chat-header">
            {/*            <Controller
              name="modelOption"
              control={control}
              render={({ field }) => (
                <SelectDropDown
                  options={modelOptions.map(option => ({
                    value: option,
                    label: option
                  }))}
                  title={field.value ? "Model" : "Select Model"}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  name={field.name}
                />
              )}
            />*/}
            <Controller
              name="state"
              control={control}
              render={({field}) => (
                <SelectDropDown
                  options={statesData.map(state => ({
                    value: state,
                    label: state
                  }))}
                  title={field.value ? 'State' : 'Select State *'}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  name={field.name}
                />
              )}
            />
            <Controller
              name="payer"
              control={control}
              render={({field}) => (
                <SelectDropDown
                  options={getPayerOptions()}
                  title={field.value ? 'Payer' : 'Select Payer *'}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  name={field.name}
                />
              )}
            />
          </div>
        </div>
        <AiChat
          api={api}
          adapter={myCustomAdapter}
          displayOptions={{themeId: 'nova', colorScheme: 'light'}}
          personaOptions={{
            assistant: {
              name: 'AI Chatbot',
              tagline:
                'Ask me anything about pain management prior authorizations',
              avatar: BotAvatar
            }
          }}
          composerOptions={{
            placeholder: 'Ask your question'
          }}
          conversationOptions={conversationOptions}
          messageOptions={{
            promptRenderer: CustomPromptRenderer
          }}
        />
      </div>
    </div>
  );
};

export default Chat;
