import AppInitializer from './AppInitializer';
import {useEffect} from 'react';
import Services from './services/Services';

// This component is just going to serve as a wrapper for the Main Application
// Any route independent global thing can be put here
const App = (): JSX.Element => {
  useEffect(() => {
    Services.init();
  }, []);

  return <AppInitializer />;
};

export default App;
