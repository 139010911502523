import axios, {CancelTokenSource} from 'axios';
import Services from './Services';

type ServiceRequestUrl = string;

type ServiceRequestBaseParams = Record<string, unknown>;

interface ServiceRequestGetParams extends ServiceRequestBaseParams {
  payload: any;
}

interface ServiceRequestPostParams extends ServiceRequestBaseParams {
  payload: any;
}

interface ServiceRequestPutParams extends ServiceRequestBaseParams {
  payload: any;
}

interface ServiceRequestDeleteParams extends ServiceRequestBaseParams {
  payload: any;
}

// wrapper between our services and axios - on how to handle the request!
class ServiceRequest {
  static async get(
    url: ServiceRequestUrl,
    params?: ServiceRequestGetParams,
    cancelToken?: CancelTokenSource
  ): Promise<any> {
    try {
      if (cancelToken?.token) {
        const response = await axios.get(url, {
          cancelToken: cancelToken.token
        });
        return response.data;
      } else {
        const response = await axios.get(url, {
          params: params && params.payload ? params.payload : undefined
        });
        return response.data;
      }
    } catch (err: any) {
      if (axios.isCancel(err)) {
        return {};
      } else {
        // handle error
        if (err.response && err.response.data) {
          throw err.response.data;
        } else {
          Services.genericErrorHandler(err);
        }
      }
    }
  }

  static async getDoc(
    url: ServiceRequestUrl,
    source?: CancelTokenSource,
    timeout = 0
  ): Promise<any> {
    try {
      if (source) {
        const response = await axios.get(url, {
          cancelToken: source.token,
          timeout: timeout
        });
        source.cancel();

        return response.data;
      } else {
        const response = await axios.get(url, {
          responseType: 'blob'
        });
        return response.data;
      }
    } catch (err: any) {
      if (axios.isCancel(err)) {
        return {};
      } else {
        // handle error
        if (err.response && err.response.data) {
          throw err.response.data;
        } else {
          Services.genericErrorHandler(err);
        }
      }
    }
  }

  static async post(
    url: ServiceRequestUrl,
    params: ServiceRequestPostParams,
    cancelToken?: CancelTokenSource,
    timeOut = 0
  ): Promise<any> {
    try {
      if (cancelToken?.token) {
        const response = await axios.post(url, params.payload, {
          cancelToken: cancelToken.token,
          timeout: timeOut
        });
        cancelToken.cancel();
        return response.data;
      } else {
        const response = await axios.post(url, params.payload, {
          timeout: timeOut
        });
        return response.data;
      }
    } catch (err: any) {
      if (axios.isCancel(err)) {
        throw err?.message;
      } else {
        if (err.response && err.response.data) {
          throw err.response.data;
        } else {
          Services.genericErrorHandler(err);
        }
      }
    }
  }

  static async postDoc(
    url: ServiceRequestUrl,
    params: ServiceRequestPostParams,
    timeOut = 0
  ): Promise<any> {
    const {payload} = params;
    try {
      const response = await axios.post(url, payload, {
        headers: {
          'Content-Type': 'multipart/form-data;'
        },
        timeout: timeOut
      });

      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data) {
        throw err.response.data;
      } else {
        Services.genericErrorHandler(err);
      }
    }
  }

  static async put(
    url: ServiceRequestUrl,
    params: ServiceRequestPutParams,
    timeout = 0
  ): Promise<any> {
    const {payload} = params;
    try {
      const response = await axios.put(url, payload, {timeout: timeout});
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data) {
        throw err.response.data;
      } else {
        Services.genericErrorHandler(err);
      }
    }
  }

  static async delete(
    url: ServiceRequestUrl,
    params: ServiceRequestDeleteParams,
    timeout = 0
  ): Promise<any> {
    try {
      const response = await axios.delete(url, {
        params: params && params.payload ? params.payload : undefined,
        timeout: timeout
      });
      return response.data;
    } catch (err: any) {
      if (err.response && err.response.data) {
        throw err.response.data;
      } else {
        Services.genericErrorHandler(err);
      }
    }
  }
}

export default ServiceRequest;
