import ServiceRequest from "../ServiceRequest";

const chatAskQuestionUrl = `/chat/ask`;

export const chatAskQuestion = async (message: string, metadata?: Record<string, any>): Promise<any> => {
  return await ServiceRequest.post(chatAskQuestionUrl, {
    payload: {
      question: message,
      metadata
    },
  });
};
