import {PRIVATE_ROUTES} from '@constants/RouteConstants';
import Chat from '@modules/chat/main/Chat';
import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';

const PrivateRoutes = (): React.ReactNode => {
  return (
    <Router>
      <Routes>
        <Route path={PRIVATE_ROUTES.CHAT} element={<Chat />} />
        <Route path="*" element={<Navigate to={PRIVATE_ROUTES.CHAT} />} />
      </Routes>
    </Router>
  );
};

export default PrivateRoutes;
