import React, {ReactElement, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import AuthUtils from './utils/AuthUtils';
import Services from './services/Services';
import Home from '@modules/home/container/Home';
import PublicHome from '@modules/home/container/PublicHome';
import {login, logout} from '@modules/home/state/auth/AuthStateSlice';

const AppInitializer = (): ReactElement => {
  const [isAppReady, setAppReady] = useState(false);
  const dispatch = useDispatch();
  const token = AuthUtils.getAuthToken();
  // const isAuthenticated = useSelector(
  //   (state: RootState) => state.auth.isAuthenticated
  // );
  const isAuthenticated = true;

  const attachAppListeners = (): void => {
    // Any app listeners to be added
  };
  const removeAppListeners = (): void => {
    // App app listeners to be removed
  };

  useEffect(() => {
    const onMount = async () => {
      if (token) {
        dispatch(login());
        attachAppListeners();
        Services.initAfterAuth({token});
      } else {
        dispatch(logout());
      }
      setTimeout(() => {
        setAppReady(true);
      }, 100);
    };
    onMount();
    return () => {
      removeAppListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRoutes = (): React.ReactNode => {
    if (!isAppReady) {
      return <div>Loading...</div>;
    } else if (isAuthenticated) {
      return <Home />;
    } else {
      return <PublicHome />;
    }
  };
  return <React.Suspense>{renderRoutes()}</React.Suspense>;
};
export default AppInitializer;
