import {createSlice} from '@reduxjs/toolkit';

import {AuthenticationStateType} from './AuthStateTypes';

const initialState: AuthenticationStateType = {
  isAuthenticated: false
};

const authenticationSlice = createSlice({
  initialState,
  name: 'authentication',
  reducers: {
    login: state => {
      state.isAuthenticated = true;
    },
    logout: state => {
      state.isAuthenticated = false;
    }
  }
});

export const {login, logout} = authenticationSlice.actions;
export default authenticationSlice.reducer;
