import {PUBLIC_ROUTES} from '@constants/RouteConstants';

import {
  LoginParams,
  LoginResponseMapper
} from '../service-mappers/PublicServiceMapper';
import ServiceRequest from '../ServiceRequest';

const loginUrl = `/api/v1/auth/${PUBLIC_ROUTES.LOGIN}`;

export const loginService = async (
  values: LoginParams
): Promise<LoginResponseMapper> => {
  return await ServiceRequest.post(loginUrl, {
    payload: values
  });
};
